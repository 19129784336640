import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage, } from "react-intl";
import { useSelector } from "react-redux";
import OnboardingModalDesktop from "./Desktop/OnboardingModalDesktop";
import OnboardingModalMobile from "./Mobile/OnboardingModalMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sh from "bundles/common/utils/sh";
import {
  uploadResume,
  attachResumeToCandidate,
} from "bundles/common/utils/uploadResume";

const onboardingSteps = {
  1: ["availability", "desired_sector"],
  2: [
    "candidate_status",
    "experience_year",
    "job_title",
    "country",
    "region",
    "highest_degree",
    "work_permit"
  ],
  3: ["resume_url"],
  4: [],
};

const OnboardingModal = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const currentCandidate = useSelector((state) => state.current_candidate);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const intl = useIntl();

  const initialFormValues = {
    availability: "",
    desired_sector: currentCandidate.desired_sector || "",
    candidate_status: currentCandidate.candidate_status || "",
    experience_year: currentCandidate.experience_year || "",
    job_title: currentCandidate.job_title || "",
    highest_degree: currentCandidate.highest_degree || "",
    work_permit: currentCandidate.work_permit || "",
    resume_url: currentCandidate.resume_url || "",
    country: currentCandidate.country || "",
    region: currentCandidate.region || "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [displayableSteps, setDisplaybleSteps] = useState(
    Object.keys(onboardingSteps)
  );

  const welcomeLabel = () => {
    if (currentStep < 4) {
      let today = new Date();
      let currentHours = today.getHours();

      if (currentHours < 12) {
        return intl.formatMessage({ id: "good_morning" });
      } else if (currentHours < 18) {
        return intl.formatMessage({ id: "good_afternoon" });
      } else {
        return intl.formatMessage({ id: "good_evening" });
      }
    } else {
      return intl.formatMessage({ id: "good_job" });
    }
  };

  useEffect(() => {
    const uncompletedSteps = Object.keys(onboardingSteps).filter((step) =>
      onboardingSteps[step].some(isAttributeEmpty)
    );

    setDisplaybleSteps(
      uncompletedSteps.concat([Object.keys(onboardingSteps).at(-1)])
    );
    if (uncompletedSteps.length > 0) {
      setCurrentStep(parseInt(uncompletedSteps[0]));
    } else {
      setCurrentStep(parseInt(Object.keys(onboardingSteps).at(-1)));
    }
  }, []);

  useEffect(() => {
    if (currentStep == 4) {
      sh.get("/job_recommendations", {
        params: {
          id: currentCandidate.id,
        },
      })
        .then((res) => {
          if (res?.data) {
            setRecommendations(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentStep]);

  const isAttributeEmpty = (attr) => {
    return values[attr] == null || values[attr] == "";
  };

  const handleCardSelected = (name, value) => {
    handleChange(name, value);
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("availability" in fieldValues) {
      temp.availability = fieldValues.availability ? "" : required;
    }

    if ("desired_sector" in fieldValues) {
      temp.desired_sector = fieldValues.desired_sector ? "" : required;
    }

    if ("candidate_status" in fieldValues) {
      temp.candidate_status = fieldValues.candidate_status ? "" : required;
    }

    if ("experience_year" in fieldValues) {
      temp.experience_year = fieldValues.experience_year ? "" : required;
    }

    if ("job_title" in fieldValues) {
      temp.job_title = fieldValues.job_title ? "" : required;
    }

    if ("highest_degree" in fieldValues) {
      temp.highest_degree = fieldValues.highest_degree ? "" : required;
    }

    if ("resume_url" in fieldValues) {
      temp.resume_url = resume.length > 0 || values.resume_url ? "" : required;
    }

    if ("country" in fieldValues) {
      temp.country = fieldValues.country ? "" : required;
    }

    if ("region" in fieldValues) {
      temp.region = fieldValues.region ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleNextClicked = () => {
    setLoading(true);
    if (currentStep == 3) {
      if (resume.length > 0) {
        uploadResume(resume[0]).then((res) => {
          attachResumeToCandidate({
            id: currentCandidate.id,
            authenticity_token: authenticityToken,
            blob_signed_id: res.blob_signed_id,
          }).then(() => updateCandidate());
        });
      } else {
        validate({ resume_url: values.resume_url });
        setLoading(false);
      }
    } else {
      updateCandidate();
    }
  };

  const updateCandidate = () => {
    const currentStepAttributes = onboardingSteps[currentStep];

    let candidate = {};
    currentStepAttributes.forEach((attr) => {
      candidate[attr] = values[attr];
    });

    if (validate(candidate)) {
      sh.put("candidate", {
        authenticity_token: authenticityToken,
        id: currentCandidate.id,
        candidate: candidate,
      })
        .then(() => {
          if (candidate.work_permit === false && currentCandidate.whitelisted === true) {
            sh.post("/candidate/blacklist", {
              authenticity_token: authenticityToken,
              id: currentCandidate.id,
              candidate: candidate,
            })
              .catch((err) => {
                console.error(err);
              });
          }
          goNextStep();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const goNextStep = () => {
    const currentStepIndex = displayableSteps.indexOf(`${currentStep}`);
    setCurrentStep(parseInt(displayableSteps[currentStepIndex + 1]));
  };

  return (
    <>
      {isDesktop
        ?
        <OnboardingModalDesktop
          currentStep={currentStep}
          displayableSteps={displayableSteps}
          welcomeLabel={welcomeLabel}
          values={values}
          errors={errors}
          loading={loading}
          resume={resume}
          recommendations={recommendations}
          handleCardSelected={handleCardSelected}
          handleInputValue={handleInputValue}
          handleNextClicked={handleNextClicked}
          setResume={setResume}
          goNextStep={goNextStep}
        />
        :
        <OnboardingModalMobile
          currentStep={currentStep}
          displayableSteps={displayableSteps}
          welcomeLabel={welcomeLabel}
          values={values}
          errors={errors}
          loading={loading}
          resume={resume}
          recommendations={recommendations}
          handleCardSelected={handleCardSelected}
          handleInputValue={handleInputValue}
          handleNextClicked={handleNextClicked}
          setResume={setResume}
          goNextStep={goNextStep}
        />
      }
    </>
  );
};

export default OnboardingModal;
