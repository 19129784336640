import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSelector } from "react-redux";
import Wip from "pictures/wip.png";
import CandidateDialog from "../../common/components/CandidateDialog";
import PaperBox from "bundles/common/components/PaperBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import CandidateList from "./CandidateList";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/styles";
import CandidateSummary from "bundles/common/components/CandidateSummary";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import MobileChurchBar from "bundles/common/components/Mobile/MobileChurchBar";
import AdvancedFiltering from "./CandidateFiltering/AdvancedFiltering";
import MobileAdvancedFiltering from "./MobileAdvancedFiltering";

const CandidateIndex = () => {
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const intl = useIntl();
  const navigate = useNavigate();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [queryText, setQueryText] = useState("");
  const [selectedDesiredLocations, setSelectedDesiredLocations] = useState([]);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedHardSkills, setSelectedHardSkills] = useState([]);
  const [selectedSoftSkills, setSelectedSoftSkills] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedHighestDegrees, setSelectedHighestDegrees] = useState([]);
  const [firstSelected, setFirstSelected] = useState(false);
  const [onlyFavorites, setOnlyFavorites] = useState(
    searchParams.get("onlyFavorites") === "true" || false
  );
  const resumeLibraryCredit = currentRecruiter.resume_library_access;

  const WipIcon = (props) => {
    return (
      <Box
        component="img"
        id="Wip-squarehub"
        alt="Squarehub"
        {...props}
        src={Wip}
      />
    );
  };

  const fetchCandidates = (
    page = 1,
    query = queryText,
    filters = createFilters()
  ) => {
    setLoading(true);
    sh.get("search/candidates", {
      params: {
        authenticity_token,
        query,
        page,
        filters,
      },
    })
      .then((res) => {
        setCandidates(res.data?.data);
        if (res.data?.data?.length > 0 && isDesktop) {
          setFirstSelected(false);
          setSelectedCandidate(res.data.data[0]);
        } else {
          setSelectedCandidate(null);
        }
        setPageCount(res.data?.pagy?.pages);
        if (!query && Object.keys(filters).length === 0) {
          setCount(res?.data?.candidate_count);
        } else {
          setCount(res?.data?.pagy?.count);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.status === 401) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.error.unauthorized",
            }),
            {
              variant: "error",
              autoHideDuration: 15000,
            }
          );
          navigate(`/${i18nLocale}/recruiter`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createFilters = () => {
    let filters = {};
    if (selectedAvailabilities?.length > 0)
      Object.assign(filters, { availability: selectedAvailabilities });
    if (selectedSectors?.length > 0)
      Object.assign(filters, { desired_sector: selectedSectors });
    if (selectedExperiences?.length > 0) {
      let experienceArray = [];
      for (let i = 0; i < selectedExperiences.length; i++) {
        experienceArray = experienceArray.concat(
          selectedExperiences[i].value || selectedExperiences[i]
        );
      }
      Object.assign(filters, { experience_year: experienceArray });
    }
    if (selectedDesiredLocations?.length > 0) {
      Object.assign(filters, {
        desired_locations: selectedDesiredLocations.concat([null]),
      });
      Object.assign(filters, {
        region: selectedDesiredLocations,
      });
    }

    if (selectedStatuses?.length > 0) {
      Object.assign(filters, {
        candidate_type: selectedStatuses,
      });
    }
    if (
      selectedHardSkills?.length > 0 ||
      selectedSoftSkills?.length > 0 ||
      selectedLanguages?.length > 0
    ) {
      Object.assign(filters, {
        skills: (selectedHardSkills || [])
          .concat(selectedSoftSkills || [])
          .concat(selectedLanguages || []),
      });
    }
    if (selectedHighestDegrees?.length > 0) {
      Object.assign(filters, { highest_degree: selectedHighestDegrees });
    }
    if (onlyFavorites) {
      Object.assign(filters, {
        only_favorites: true,
      });
    }

    return filters;
  };

  useEffect(() => {
    if (searchParams.get("onlyFavorites") === "true") {
      setOnlyFavorites(true);
    } else {
      setOnlyFavorites(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!onlyFavorites) {
      searchParams.delete("onlyFavorites");
      setSearchParams(searchParams);
    }
  }, [onlyFavorites]);

  useEffect(() => {
    if (isDesktop) {
      fetchCandidates();
    }
  }, [
    selectedAvailabilities,
    selectedSectors,
    selectedExperiences,
    selectedStatuses,
    selectedHardSkills,
    selectedSoftSkills,
    selectedLanguages,
    selectedDesiredLocations,
    selectedHighestDegrees,
    onlyFavorites,
    queryText,
    isDesktop,
  ]);

  useEffect(() => {
    if (!isDesktop) {
      fetchCandidates();
    }
  }, [
    selectedAvailabilities.length,
    selectedSectors.length,
    selectedExperiences.length,
    selectedStatuses.length,
    selectedDesiredLocations.length,
    selectedHighestDegrees.length,  
    onlyFavorites,
    isDesktop,
  ]);

  useEffect(() => {
    fetchCandidates(page, queryText, createFilters());
  }, [page]);

  useEffect(() => {
    if (!isDesktop) return;

    if (firstSelected) {
      document
        .getElementById("candidate-scroll-anchor")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (selectedCandidate?.id) {
      setFirstSelected(true);
    }
  }, [selectedCandidate?.id]);

  const CandidateCount = () => (
    <PaperBox>
      <Typography variant="h6">
        <b>
          {count === 0
            ? intl.formatMessage(
              { id: "candidate_search.zero_results" },
              { result: count }
            )
            : count === 1
              ? intl.formatMessage(
                { id: "candidate_search.one_result" },
                { result: count }
              )
              : intl.formatMessage(
                { id: "candidate_search.n_results" },
                { result: new Intl.NumberFormat("fr-FR").format(count) }
              )
          }{" "}
        </b>
      </Typography>
    </PaperBox>
  );

  const closeDialog = () => {
    setSelectedCandidate(null);
  };

  const clearAdvancedFilters = () => {
    setSelectedAvailabilities([]);
    setSelectedSectors([]);
    setSelectedExperiences([]);
    setSelectedStatuses([]);
    setOnlyFavorites(false);
    setSelectedHardSkills([]);
    setSelectedSoftSkills([]);
    setSelectedLanguages([]);
    setSelectedHighestDegrees([]);
    if (!isDesktop) {
      setSelectedDesiredLocations([]);
    }
  };

  return (
    <>
      {isDesktop && (
        <Box
          style={{
            background: "linear-gradient(to left, #2A334E 0%, #101426 100%)",
            minHeight: "240px",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            alignItems: "center",
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
        >
          <AdvancedFiltering
            onlyFavorites={onlyFavorites}
            setSelectedAvailabilities={setSelectedAvailabilities}
            setSelectedSectors={setSelectedSectors}
            setSelectedExperiences={setSelectedExperiences}
            setSelectedStatuses={setSelectedStatuses}
            setSelectedHardSkills={setSelectedHardSkills}
            setSelectedSoftSkills={setSelectedSoftSkills}
            setSelectedLanguages={setSelectedLanguages}
            setOnlyFavorites={setOnlyFavorites}
            setSelectedRegions={setSelectedDesiredLocations}
            setSelectedHighestDegrees={setSelectedHighestDegrees}
            clearAdvancedFilters={clearAdvancedFilters}
            queryText={queryText}
            setQueryText={setQueryText}
            fetchRecords={fetchCandidates}
          />
        </Box>
      )}
      <Container maxWidth="lg">
        <Grid container spacing={isDesktop ? 4 : 2}>
          <BackdropScreen open={loading} />
          <Grid item xs={12}>
            {!isDesktop && (
              <>
                <MobileChurchBar
                  queryText={queryText}
                  setQueryText={setQueryText}
                  fetchRecords={fetchCandidates}
                  placeholder="landing_recruiter.searched_candidates"
                />
                <MobileAdvancedFiltering
                  selectedRegions={selectedDesiredLocations}
                  selectedAvailabilities={selectedAvailabilities}
                  selectedSectors={selectedSectors}
                  selectedExperiences={selectedExperiences}
                  selectedStatuses={selectedStatuses}
                  selectedHighestDegrees={selectedHighestDegrees}
                  onlyFavorites={onlyFavorites}
                  setSelectedRegions={setSelectedDesiredLocations}
                  setSelectedAvailabilities={setSelectedAvailabilities}
                  setSelectedSectors={setSelectedSectors}
                  setSelectedExperiences={setSelectedExperiences}
                  setSelectedStatuses={setSelectedStatuses}
                  setSelectedHighestDegrees={setSelectedHighestDegrees}
                  setOnlyFavorites={setOnlyFavorites}
                  clearAdvancedFilters={clearAdvancedFilters}
                />
              </>
            )}
          </Grid>

          {!isDesktop && (
            <Grid item xs={12} style={{ marginTop: "8px" }}>
              <CandidateCount />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            style={{ paddingTop: "0px" }}
          >
            <Grid item xs={12}>
              {isDesktop && (
                <Box>
                  <CandidateCount />
                </Box>
              )}
              <Box
                style={{
                  filter: resumeLibraryCredit ? "0" : "blur(2.5px)",
                  height: "100%",
                }}
              >
                <CandidateList
                  style={{ filter: "blur(2.5px)" }}
                  candidates={candidates}
                  activeCandidate={selectedCandidate}
                  handleCandidateClick={setSelectedCandidate}
                  loading={loading}
                  setPage={setPage}
                  page={page}
                  pageCount={pageCount}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            style={{ paddingTop: "0px" }}
          >
            {resumeLibraryCredit ? (
              <Grid container>
                {selectedCandidate &&
                  (isDesktop ? (
                    <Grid item xs={12} style={{ marginBottom: "32px" }}>
                      <Box
                        id="candidate-scroll-anchor"
                        style={{ position: "relative", top: "-16px" }}
                      ></Box>
                      <CandidateSummary
                        candidate={selectedCandidate}
                      ></CandidateSummary>
                    </Grid>
                  ) : (
                    <CandidateDialog
                      candidate={selectedCandidate}
                      isDialogOpen
                      setIsDialogOpen={closeDialog}
                    ></CandidateDialog>
                  ))}
              </Grid>
            ) : (
              <Box
                md={6}
                height={"80vh"}
                display={"flex"}
                flexDirection={"column"}
                container
                justifyContent={"space-evenly"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography variant="h5">
                    <FormattedMessage id="credits_box.no_cv_access"></FormattedMessage>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    style={{ width: "200px" }}
                    onClick={() =>
                      navigate({
                        pathname: `/${i18nLocale}/recruiter/pricing`,
                      })
                    }
                    variant="rounded"
                    color="primaryContained"
                  >
                    <Typography
                      align="center"
                      variant="h6"
                      style={{
                        paddingBottom: "1px",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                    >
                      <b>
                        <FormattedMessage id="order.order" />
                      </b>
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <WipIcon />
                </Grid>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box my={2} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CandidateIndex;
