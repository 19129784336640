import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  ListItemText,
  MenuItem,
  Modal,
  IconButton,
  Divider,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SelectRegion from "../../common/components/SelectRegion";
import {
  companyIndustryMap,
  jobStatuses,
  availabilities,
  experience,
} from "bundles/common/utils/constants";
import { truncate } from "../../common/utils/utils";
import { degrees } from "bundles/common/utils/constants";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "32px 24px",
  },
  item: {
    cursor: "pointer",
    alignItems: "center",
    padding: "0px 0px 4px 16px",
  },
  checkboxes: {
    padding: "0px",
  },
  filterTitle: {
    color: theme.palette.common.blue,
  },
  modal: {
    overflow: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.white,
  },
  modalHeader: {
    height: "68px",
    backgroundColor: theme.palette.secondary.main,
  },
  modalBox: {},
  category: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 8px",
  },
  badge: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: "16px",
    width: "16px",
  },
  menuItem: {
    height: "24px",
    "&.Mui-selected": {
      backgroundColor: "white !important",
    },
  },
}));

const MobileAdvancedFiltering = ({
  selectedRegions,
  selectedAvailabilities,
  selectedSectors,
  selectedExperiences,
  selectedStatuses,
  selectedHighestDegrees,
  onlyFavorites,
  setSelectedRegions,
  setSelectedAvailabilities,
  setSelectedSectors,
  setSelectedExperiences,
  setSelectedStatuses,
  setSelectedHighestDegrees,
  setOnlyFavorites,
  clearAdvancedFilters,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [currentExpanded, setCurrentExpanded] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const presetRegions = query.get("selectedRegion");
    const presetSectors = query.get("selectedSector");
    const presetExperiences = query.get("selectedExperience");

    if (presetRegions) setSelectedRegions(presetRegions.split(","));
    if (presetSectors) setSelectedSectors(presetSectors.split(","));
    if (presetExperiences) {
      const yearsArray = presetExperiences.split(",").map(Number).sort((a, b) => a - b);
      const formattedExperiences = [];
      const ranges = [
        { min: 0, max: 2 },
        { min: 3, max: 5 },
        { min: 6, max: 10 }
      ];
      ranges.forEach((range, index) => {
        const filteredYears = yearsArray.filter(year => year >= range.min && year <= range.max);
        if (filteredYears.length > 0) {
          const startYear = range.min;
          const endYear = range.max;
          const label = `${startYear}-${endYear}`;
          const experienceObject = {
            id: index + 1,
            label: label,
            value: Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)
          };
          formattedExperiences.push(experienceObject);
        }
      });
      if (presetExperiences.includes("10+")) {
        const label = "11-20";
        const experienceObject = {
          id: ranges.length + 1,
          label: label,
          value: Array.from({ length: 10 }, (_, i) => i + 11)
        };
        formattedExperiences.push(experienceObject);
      }
      setSelectedExperiences(formattedExperiences);
    }
  }, [query]);

  const handleAvailabilities = (availability) => {
    if (selectedAvailabilities.includes(availability)) {
      setSelectedAvailabilities(
        selectedAvailabilities.filter((a) => a != availability)
      );
    } else {
      setSelectedAvailabilities(selectedAvailabilities.concat(availability));
    }
  };

  const handleSectors = (sector) => {
    if (selectedSectors.includes(sector)) {
      setSelectedSectors(selectedSectors.filter((a) => a != sector));
    } else {
      setSelectedSectors(selectedSectors.concat(sector));
    }
  };

  const handleExperiences = (xp) => {
    const existingLabels = selectedExperiences.map((exp) => exp.label);
    if (existingLabels.includes(xp.label)) {
      setSelectedExperiences(selectedExperiences.filter((exp) => exp.label !== xp.label));
    } else {
      setSelectedExperiences([...selectedExperiences, xp]);
    }
  };

  const handleStatuses = (status) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((a) => a != status));
    } else {
      setSelectedStatuses(selectedStatuses.concat(status));
    }
  };

  const handleDegrees = (deg) => {
    if (selectedHighestDegrees.includes(deg)) {
      setSelectedHighestDegrees(selectedHighestDegrees.filter((a) => a !== deg));
    } else {
      setSelectedHighestDegrees([...selectedHighestDegrees, deg]);
    }
  };

  const onModalCloses = () => {
    setCurrentExpanded(null);
    setExpanded(false);
  };

  const handleCategoryExpansion = (category) => {
    return currentExpanded == category
      ? setCurrentExpanded(null)
      : setCurrentExpanded(category);
  };

  const totalFilters =
    (selectedAvailabilities.length || 0) +
    (selectedExperiences.length || 0) +
    (selectedSectors.length || 0) +
    (selectedStatuses.length || 0) +
    (selectedRegions.length || 0) +
    (selectedHighestDegrees.length || 0) +
    (onlyFavorites ? 1 : 0);

  const hasActiveFilters = totalFilters > 0;

  useEffect(() => {
    if (!expanded) setCurrentExpanded(null);
  }, [expanded]);

  return (
    <Box id="sh-advanced-filtering">
      <Box display="flex" justifyContent="center">
        <Button
          variant="textUnderline"
          style={{
            marginTop: "8px",
            marginBottom: hasActiveFilters ? "0px" : "4px",
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box>
            <FormattedMessage id="job_search.advanced_search" />
            {"  "}
            {hasActiveFilters && <span>({totalFilters})</span>}
          </Box>
        </Button>
      </Box>

      {hasActiveFilters && (
        <Box textAlign="center">
          <Button
            variant="text"
            style={{
              padding: "0px",
              marginBottom: "4px",
              height: "fit-content",
            }}
            onClick={clearAdvancedFilters}
          >
            <Typography variant="body3">
              <FormattedMessage id="job_search.clear_filters" />
            </Typography>
          </Button>
        </Box>
      )}

      <Modal
        open={expanded}
        onClose={onModalCloses}
        disableAutoFocus={true}
        className={classes.modal}
        hideBackdrop={true}
      >
        <Box className={classes.modalContainer}>
          <Box className={classes.modalHeader}>
            <IconButton
              aria-label="af-go-back"
              onClick={() => setExpanded(false)}
              style={{
                top: "14px",
                left: "16px",
                position: "absolute",
              }}
            >
              <ArrowCircleLeftIcon
                fontSize="medium"
                style={{ color: "white" }}
              ></ArrowCircleLeftIcon>
            </IconButton>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%" }}
            >
              <Typography
                variant="h2"
                style={{ color: theme.palette.common.white, fontWeight: 600 }}
              >
                <FormattedMessage id="job_search.advanced_search" />
              </Typography>
            </Box>
          </Box>
          <Box className={classes.modalBox}>
            {/* Regions */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("region")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="candidate_search.regions"/>
                      {selectedRegions.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedRegions.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedRegions.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedRegions
                            .map((r) => {
                              return intl.formatMessage({
                                id: `regions.${r}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "region" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "region" && (
                <Box>
                  <SelectRegion
                    required
                    multiple
                    labelId="landing_recruiter.searched_location"
                    value={selectedRegions}
                    handleInputValue={(values) => setSelectedRegions(values)}
                    displayEmpty={true}
                    list={true}
                  />
                </Box>
              )}
              <Divider />
            </Box>

            {/* Availabilities */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("availability")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="candidate.availability"/>
                      {selectedAvailabilities.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedAvailabilities.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedAvailabilities.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedAvailabilities
                            .map((a) => {
                              return intl.formatMessage({
                                id: `candidate.${a}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "availability" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "availability" && (
                <Box>
                  {availabilities.map((availability) => {
                    return (
                      <MenuItem
                        onClick={() => handleAvailabilities(availability)}
                        key={availability}
                        value={availability}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox
                          checked={selectedAvailabilities?.includes(
                            availability
                          )}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `candidate.${availability}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Sectors */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("sectors")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="candidate_search.sectors"/>
                      {selectedSectors.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedSectors.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedSectors.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedSectors
                            .map((a) => {
                              return intl.formatMessage({
                                id: `company.company_industry.${a}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "sectors" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "sectors" && (
                <Box>
                  {companyIndustryMap.map((sector) => {
                    return (
                      <MenuItem
                        onClick={() => handleSectors(sector)}
                        key={sector}
                        value={sector}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox checked={selectedSectors?.includes(sector)} />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `company.company_industry.${sector}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Experience */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("experience")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="experience.experience"/>
                      {selectedExperiences.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedExperiences.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedExperiences.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedExperiences
                            .map((selectedExperience) => {
                              return `${
                                selectedExperience.label
                              } ${intl.formatMessage({
                                id: "candidate.years",
                              })}`;
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "experience" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "experience" && (
                <Box>
                  {experience.map((xp) => {
                    return (
                      <MenuItem
                        onClick={() => handleExperiences(xp)}
                        key={xp.label}
                        value={xp}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox checked={selectedExperiences.some(exp => exp.label === xp.label)} />
                        <ListItemText
                          primary={`${xp.label} ${intl.formatMessage({
                            id: "candidate.years",
                          })}`}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Status */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("status")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="project.status_only"/>
                      {selectedStatuses.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedStatuses.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedStatuses.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedStatuses
                            .map((selectedStatus) => {
                              return intl.formatMessage({
                                id: `candidate.status_map.${selectedStatus}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "status" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "status" && (
                <Box>
                  {jobStatuses.map((st) => {
                    return (
                      <MenuItem
                        onClick={() => handleStatuses(st)}
                        key={st}
                        value={st}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox checked={selectedStatuses?.includes(st)} />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `candidate.status_map.${st}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("degree")}
              >
                <Box>
                  <Typography
                    variant="body1bold"
                    color="secondary"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <FormattedMessage id="onboarding.degree.degree"/>
                    {selectedHighestDegrees.length > 0 && (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "8px",
                          textAlign: "center",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          color: theme.palette.common.white,
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        <Typography variant="body3">
                          {selectedHighestDegrees.length}
                        </Typography>
                      </Box>
                    )}
                  </Typography>

                  {selectedHighestDegrees.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedHighestDegrees
                            .map((deg) =>
                              intl.formatMessage({
                                id: `onboarding.degree.${deg}`,
                              })
                            )
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded === "degree" ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </Box>

              {currentExpanded === "degree" && (
                <Box>
                  {degrees.map((degree) => (
                    <MenuItem
                      onClick={() => handleDegrees(degree.code)}
                      key={degree.code}
                      value={degree.code}
                    >
                      <Checkbox checked={selectedHighestDegrees.includes(degree.code)} />
                      <ListItemText
                        primary={intl.formatMessage({ id: degree.label })}
                      />
                    </MenuItem>
                  ))}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Favorites */}
            <Box>
              <Box
                className={classes.category}
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  paddingRight: "0px",
                }}
                onClick={() => setOnlyFavorites(!onlyFavorites)}
              >
                <Box style={{ flexGrow: 1 }}>
                  <Box style={{ flexGrow: 1 }}>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormattedMessage id="candidate_search.favorites" />
                      <Checkbox checked={onlyFavorites} />
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MobileAdvancedFiltering;
