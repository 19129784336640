import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles, useTheme } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Button,
  TextField,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import { truncate } from "bundles/common/utils/utils";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import CardSelect from "bundles/common/components/CardSelect";
import FileDropzone from "bundles/common/components/FileDropzone";
import {
  availabilities,
  companyIndustryMap,
  candidateStatus,
  be_regions,
  lu_regions,
  fr_regions,
  degrees
} from "bundles/common/utils/constants";

const countryRegionsMap = {
  belgium: be_regions,
  luxembourg: lu_regions,
  france: fr_regions,
};

const OnboardingModalMobile = ({
  values,
  errors,
  setErrors,
  handleCardSelected,
  handleInputValue,
  handleNextClicked,
  goNextStep,
  currentStep,
  recommendations,
  loading,
  setResume,
  resume,
  welcomeLabel,
  displayableSteps,
}) => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const [selectedCountry, setSelectedCountry] = useState(values.country || "");

  const intl = useIntl();
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    modal: {
      width: "100%",
      height: "100%",
      padding: "8px",
    },
    modalContainer: {
      backgroundColor: theme.palette.common.white,
      height: "100%",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      padding: "16px",
    },
    modalHeader: {},
    modalTitle: {
      display: "flex",
      justifyContent: "center",
      marginTop: "16px",
    },
    stepperContainer: {
      width: "100%",
      marginTop: "48px",
      marginBottom: "32px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    stepper: {
      marginLeft: "auto",
      marginRight: "auto",
      "& .MuiStepLabel-iconContainer": {
        padding: "0px",
      },
      "& .MuiStepIcon-root": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.primary.main} !important`,
      },
      "& .MuiStepIcon-active": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.primary.main} !important`,
      },
      "& .MuiStepIcon-completed": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.primary.main} !important`,
      },
      "& .Mui-disabled .MuiStepIcon-root": {
        height: "40px",
        width: "40px",
        color: `${theme.palette.text.disabled} !important`,
      },
    },
    modalContent: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      height: "100%",
      rowGap: "32px",
    },
    stepBox: {
      minWidth: "100%",
      maxWidth: "100%",
    },
    inputBox: {},
    inputTitle: {
      marginBottom: "16px !important",
    },
    forwardButton: {
      marginTop: "16px !important",
      width: "200px",
    },
    recommendations: {
      borderRadius: "4px !important",
      width: "100% !important",
      cursor: "pointer",
      textAlign: "left",
      padding: "16px !important",
      backgroundColor: theme.palette.background.paper,
      boxShadow: "0 3px 7px rgba(0, 0, 0, 0.3) !important",
      textDecoration: "none !important",
    },
  }));
  const classes = useStyles();

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    handleInputValue({ target: { name: "country", value: newCountry } });
  };

  const handleRegionChange = (event) => {
    const newRegion = event.target.value;
    handleInputValue({ target: { name: "region", value: newRegion } });
  };

  return (
    <Box className={classes.modal}>
      <Box className={classes.modalContainer}>
        <Box className={classes.modalHeader}>
          <Box className={classes.modalTitle}>
            <Typography
              variant="h1"
              style={{
                color: theme.palette.primary.main,
                whiteSpace: "nowrap",
              }}
            >
              {welcomeLabel()}
            </Typography>
            <Typography
              variant="h1"
              style={{ whiteSpace: "nowrap" }}
            >
              , {currentCandidate.first_name} {currentStep == 4 ? "👌" : "👋"}
            </Typography>
          </Box>
          {currentStep < 4 && (
            <Box className={classes.stepperContainer}>
              <Stepper
                alternativeLabel
                activeStep={displayableSteps.indexOf(`${currentStep}`)}
                className={classes.stepper}
                color="secondary"
              >
                {displayableSteps.map((step) => {
                  return (
                    <Step key={step}>
                      <StepLabel />
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          )}
        </Box>
        <form className={classes.modalContent}>
          {currentStep == 1 && (
            <Box className={classes.stepBox}>
              <Box
                className={classes.inputBox}
                style={{ marginBottom: "32px" }}
              >
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.looking_for" />
                </Typography>
                <CardSelect
                  color="primary"
                  fieldName="desired_sector"
                  selected={values.desired_sector}
                  values={companyIndustryMap.map((industry) => {
                    return {
                      code: industry,
                      label: intl.formatMessage({
                        id: `company.company_industry.${industry}`,
                      }),
                    };
                  })}
                  onChange={handleCardSelected}
                />
                {errors["desired_sector"] && (
                  <p
                    className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                    style={{ color: "#f44336" }}
                  >
                    {errors["desired_sector"]}
                  </p>
                )}
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.looking_how" />
                </Typography>
                <CardSelect
                  color="primary"
                  fieldName="availability"
                  selected={values.availability}
                  values={availabilities.map((availability) => {
                    return {
                      code: availability,
                      label: intl.formatMessage({
                        id: `candidate.${availability}`,
                      }),
                    };
                  })}
                  onChange={handleCardSelected}
                />
                {errors["availability"] && (
                  <p
                    className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                    style={{ color: "#f44336" }}
                  >
                    {errors["availability"]}
                  </p>
                )}
              </Box>
            </Box>
          )}
          {currentStep == 2 && (
            <Box className={classes.stepBox}>
              <Box
                className={classes.inputBox}
                style={{ marginBottom: "32px" }}
              >
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.status" />
                </Typography>
                <Box>
                  <CardSelect
                    color="primary"
                    fieldName="candidate_status"
                    selected={values.candidate_status}
                    values={candidateStatus.map((status) => {
                      return {
                        code: status,
                        label: intl.formatMessage({
                          id: `candidate.status_map.${status}`,
                        }),
                      };
                    })}
                    onChange={handleCardSelected}
                  />
                  {errors["candidate_status"] && (
                    <p
                      className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                      style={{ color: "#f44336" }}
                    >
                      {errors["candidate_status"]}
                    </p>
                  )}
                </Box>
              </Box>
              <Box
                className={classes.inputBox}
                style={{ marginBottom: "32px" }}
              >
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.experience" />
                </Typography>
                <TextField
                  fullWidth
                  required
                  value={values.experience_year}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  name="experience_year"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  variant="outlined"
                  label={<FormattedMessage id="candidate.experience" />}
                  {...(errors["experience_year"] && {
                    error: true,
                    helperText: errors["experience_year"],
                  })}
                />
              </Box>
              <Box className={classes.inputBox} style={{ marginBottom: "32px" }}>
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.job_title" />
                </Typography>
                <TextField
                  fullWidth
                  required
                  value={values.job_title}
                  type="text"
                  name="job_title"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  variant="outlined"
                  label={<FormattedMessage id="candidate.job_title" />}
                  {...(errors["job_title"] && {
                    error: true,
                    helperText: errors["job_title"],
                  })}
                />
              </Box>
              <Box className={classes.inputBox} style={{ marginBottom: "32px" }}>
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.country" />
                </Typography>
                <Select
                  fullWidth
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  name="country"
                  displayEmpty
                  variant="outlined"
                  renderValue={(selected) =>
                    selected ? intl.formatMessage({ id: `countries.${selected}` }) :
                      <span style={{ color: "#aaa" }}>{intl.formatMessage({ id: "job_offer.country" })}</span>
                  }
                >
                  <MenuItem value="belgium">{intl.formatMessage({ id: "country.be" })}</MenuItem>
                  <MenuItem value="luxembourg">{intl.formatMessage({ id: "country.lu" })}</MenuItem>
                  <MenuItem value="france">{intl.formatMessage({ id: "country.fr" })}</MenuItem>
                </Select>
              </Box>
              <Box className={classes.inputBox} style={{ marginBottom: "32px" }}>
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.region" />
                </Typography>
                <Select
                  fullWidth
                  value={values.region || ""}
                  onChange={handleRegionChange}
                  name="region"
                  displayEmpty
                  variant="outlined"
                  disabled={!selectedCountry}
                  renderValue={(selected) =>
                    selected ? intl.formatMessage({ id: `regions.${selected}`, defaultMessage: selected }) :
                      <span style={{ color: "#aaa" }}>{intl.formatMessage({ id: "regions.region" })}</span>
                  }
                >
                  {selectedCountry && countryRegionsMap[selectedCountry]?.length ? (
                    countryRegionsMap[selectedCountry].map((region) => (
                      <MenuItem key={region} value={region}>
                        {intl.formatMessage({ id: `regions.${region}`, defaultMessage: region })}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>{intl.formatMessage({ id: "oauth.errors.linkedin_error", defaultMessage: "No regions available" })}</MenuItem>
                  )}
                </Select>
              </Box>
              <Box className={classes.inputBox} style={{ marginBottom: "32px" }}>
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.degree_question" />
                </Typography>

                <Select
                  fullWidth
                  value={values.highest_degree || ""}
                  onChange={(e) =>
                    handleInputValue({
                      target: { name: "highest_degree", value: e.target.value },
                    })
                  }
                  name="highest_degree"
                  displayEmpty
                  variant="outlined"
                  renderValue={(selected) =>
                    selected ? (
                      <FormattedMessage id={`onboarding.degree.${selected}`} />
                    ) : (
                      <span style={{ color: "#aaa" }}>
                        <FormattedMessage id="onboarding.degree.degree" />
                      </span>
                    )
                  }
                >
                  {degrees.map((degree) => (
                    <MenuItem key={degree.code} value={degree.code}>
                      <FormattedMessage id={degree.label} />
                    </MenuItem>
                  ))}
                </Select>

                {errors["highest_degree"] && (
                  <p
                    className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                    style={{ color: "#f44336" }}
                  >
                    {errors["highest_degree"]}
                  </p>
                )}
              </Box>
              <Box className={classes.inputBox}>
                <Typography className={classes.inputTitle} variant="h4">
                  <FormattedMessage id="onboarding.work_permit" />
                </Typography>
                <CardSelect
                  color="primary"
                  fieldName="work_permit"
                  selected={values.work_permit}
                  values={[
                    {
                      code: true,
                      label: intl.formatMessage({ id: "onboarding.yes_box" }),
                    },
                    {
                      code: false,
                      label: intl.formatMessage({ id: "onboarding.no_box" }),
                    },
                  ]}
                  onChange={handleCardSelected}
                />
              </Box>
            </Box>
          )}
          {currentStep == 3 && (
            <Box className={classes.stepBox}>
              <Box className={classes.inputBox}>
                <Typography
                  className={classes.inputTitle}
                  style={{ marginBottom: "32px" }}
                  variant="h4"
                >
                  <FormattedMessage id="onboarding.resume" />
                </Typography>
                <Box>
                  <FileDropzone
                    handleFileInput={(file) => {
                      if (!resume || file.length != resume.length) {
                        setResume(file);
                        setErrors({ ...errors, resume: "" });
                      }
                    }}
                    filesLimit={1}
                    dropzoneTextId="devise.signup.drag_and_drop_zone"
                    initialFiles={values.resume_url ? [values?.resume_url] : []}
                    fileName={`${currentCandidate.first_name} ${currentCandidate.last_name} - CV.pdf`}
                    type="candidate"
                  />
                  {errors["resume_url"] && (
                    <p
                      className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
                      style={{ color: "#f44336" }}
                    >
                      {errors["resume_url"]}
                    </p>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {currentStep == 4 && (
            <Box
              className={classes.stepBox}
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Box
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    style={{
                      marginBottom: "24px",
                      marginTop: "0px",
                    }}
                  >
                    <FormattedMessage id="onboarding.selection" />
                  </Typography>

                  <Box
                    style={{
                      display: "flex",
                      columnGap: "24px",
                      rowGap: "16px",
                      flexDirection: "column",
                    }}
                  >
                    {recommendations.map((offer) => {
                      return (
                        <Link
                          href={`candidate/job_offers?jid=${offer.id}`}
                          key={`recommendation-${offer.id * 324}`}
                          className={classes.recommendations}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: theme.palette.text.primary,
                            }}
                          >
                            <Box>
                              <Typography variant="h5">
                                {truncate(offer.job_title, 30)}
                              </Typography>
                              <Typography variant="h6">
                                {offer.company.name}
                              </Typography>
                            </Box>
                            <ProfilePicture
                              picture={offer?.company?.logo_url}
                              size={60}
                              initials={offer?.company?.name[0]}
                              alt={`Logo ${offer?.company?.name}`}
                              borderRadius="4px"
                            />
                          </Box>
                        </Link>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {currentStep < 4 ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <LoadingButton
                loading={loading}
                size="large"
                variant="rounded"
                color="primaryContained"
                className={classes.forwardButton}
                onClick={handleNextClicked}
              >
                <FormattedMessage id="next" />
              </LoadingButton>
              {currentStep == 3 && (
                <Button
                  style={{ marginTop: "20px" }}
                  onClick={goNextStep}
                  variant="textUnderline"
                >
                  <Typography variant="h5">
                    <FormattedMessage id="skip_step" />
                  </Typography>
                </Button>
              )}
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                columnGap: "48px",
                rowGap: "16px",
                marginBottom: "32px",
                flexDirection: "column",
              }}
            >
              <Button
                size="large"
                variant="rounded"
                color="primaryContained"
                style={{ width: "240px" }}
                href={`/${i18nLocale}/candidate/settings`}
              >
                <FormattedMessage id="onboarding.complete_profile" />
              </Button>
              <Button
                size="large"
                variant="rounded"
                color="primaryContained"
                style={{ width: "240px" }}
                href={`/${i18nLocale}/candidate/job_offers`}
              >
                <FormattedMessage id="onboarding.find_job" />
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default OnboardingModalMobile;
